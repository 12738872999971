import images from './images'

const appetizers = [
  {
    title: 'A1. Crab Cheese Wontons',
    price: '$6.95',
    tags: '6pcs',
  },
  {
    title: 'A2. Pot Stickers',
    price: '$6.95',
    tags: '6pcs | Steamed or Fried',
  },
  {
    title: 'A3. Spring Rolls',
    price: '$5.95',
    tags: '2pcs | Grilled Chicken, Pork | Beef Add $1.00',
  },
  {
    title: 'A4. Vegetarian Spring Rolls',
    price: '$5.95',
    tags: '2pcs',
  },
  {
    title: 'A5. Vietnamese Eggrolls',
    price: '$10.95',
    tags: '6pcs',
  },
  {
    title: 'A6. Vegetarian Eggrolls',
    price: '$8.95',
    tags: '4pcs',
  },
  {
    title: 'A7. Rocket Shrimp Rolls',
    price: '$8.50',
    tags: 'Shrimp',
  },
  {
    title: 'A8. Grilled Mussels With Butter',
    price: '$7.95',
    tags: '6pcs',
  },
  {
    title: 'A10. Pho Bom Appetizers',
    price: '$32.95',
    tags: 'Grilled meat combo',
  },
  {
    title: 'A11. Vietnamese Sandwich',
    price: '$7.95',
    tags: '',
  },
]

const salads = [
  {
    title: 'S1. Wontons Soup',
    price: '$5.00 | $7.00',
    tags: 'Small | Medium | Large',
  },
  {
    title: 'S2. Asian Chicken Salad',
    price: '$11.95',
    tags: 'Goi Ga',
  },
  {
    title: 'S3. Oriental Salad',
    price: '$15.95',
    tags: '',
  },
]

const buns = [
  {
    title: 'N1. Grilled Chicken Breast with Eggrolls',
    price: '$13.95',
    tags: '',
  },
  {
    title: 'N2. Grilled Pork with Eggrolls',
    price: '$13.95',
    tags: '',
  },
  {
    title: 'N3. Grilled Beef with Eggrolls',
    price: '$13.95',
    tags: '',
  },
  {
    title: 'N4. Vietnamese Eggrolls',
    price: '$13.95',
    tags: '',
  },
  {
    title: 'N5. Vegetarian & Tofu with Vegetarian Eggrolls',
    price: '$13.95',
    tags: '',
  },
  {
    title: 'N6. Grilled Shrimp with Eggrolls',
    price: '$14.95',
    tags: '',
  },
  {
    title: 'N7. Combination',
    price: '$15.95',
    tags: 'Grilled Chicken Breast, Pork, Beef, Shrimp And Eggrolls',
  },
]

const rices = [
  {
    title: 'C1. Grilled Chicken Breast With Egg',
    price: '$13.95',
  },
  {
    title: 'C2. Grilled Pork With Egg',
    price: '$13.95',
  },
  {
    title: 'C3. Grilled Beef With Egg',
    price: '$13.95',
  },
  {
    title: 'C4. Grilled Shrimp With Egg',
    price: '$14.95',
  },
  {
    title: 'C5. Grilled Pork Chop With Egg',
    price: '$15.95',
  },
  {
    title: 'C6. Diced Steak',
    price: '$15.95',
  },
  {
    title: 'C7. Grilled Salmon',
    price: '$16.95',
  },
  {
    title: 'C8. Combination',
    price: '$15.95',
  },
]

const fried_rices = [
  {
    title: 'F1. Chicken & Mix Vegetables',
    price: '$13.95',
  },
  {
    title: 'F2. Pork & Mix Vegetables',
    price: '$13.95',
  },
  {
    title: 'F3. Beef & Mix Vegetables',
    price: '$13.95',
  },
  {
    title: 'F4. Vegetarian & Tofu',
    price: '$13.95',
  },
  {
    title: 'F5. Shrimp & Mix Vegetables',
    price: '$14.95',
  },
  {
    title: 'F6. Combination',
    price: '$15.95',
  },
  {
    title: 'F7. Crawfish & Mix Vegetables',
    price: '$15.95',
  },
]

const phos = [
  {
    title: 'P1. Meat Ball',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Bo Vien',
  },
  {
    title: 'P2. Rare Steak **',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Tai',
  },
  {
    title: 'P3. Rare Steak & Tendon **',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Tai Gan',
  },
  {
    title: 'P4. Rare Steak & Well Done Flank **',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Tai Nam',
  },
  {
    title: 'P5. Rare Steak & Well Done Brisket **',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Tai Chin',
  },
  {
    title: 'P6. Rare Steak & Tripe **',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Tai Sach',
  },
  {
    title: 'P7. Well Done Brisket',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Chin',
  },
  {
    title: 'P8. Well Done Brisket & Flank',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Chin Nam',
  },
  {
    title: 'P9. Combination **',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Pho Dac Biet | Rare Steak, Flank, Brisket, Tendon, Tripe',
  },
  {
    title: 'P10. Chicken',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Pho Ga',
  },
  {
    title: 'P11. Vietnamese’s Chicken',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Pho Ga Dai | (Additional $3.00) Only available with Medium and Large Size',
  },
  {
    title: 'P12. Shrimp',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Pho Tom',
  },
  {
    title: 'P13. Seafood',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Pho Do Bien | Fish Balls, Imitation Crab Meat, Shrimps',
  },
  {
    title: 'P14. Vegetarian + Tofu',
    price: '$12.50 | $13.50 | $15.50',
    tags: 'Pho Chay | Choice of either Vegetarian Broth or Beef Broth',
  },
]

const specicals = [
  {
    title: 'D1. Sesame Chicken',
    price: '$12.95',
    tags: '',
  },
  {
    title: 'D2. Sweet & Sour Chicken',
    price: '$12.95',
    tags: '',
  },
  {
    title: 'D3. Coconut Curry',
    price: '$13.95',
    tags: 'Beef, Pork, Chicken, or Tofu | Shrimp adds $2.00 Extra',
  },
  {
    title: 'D4. Mongolian',
    price: '$13.95',
    tags: 'Beef, Pork, Chicken, or Tofu | Shrimp adds $2.00 Extra',
  },
  {
    title: 'D5. Beef & Broccoli',
    price: '$13.95',
    tags: 'Beef, Pork, Chicken, or Tofu | Shrimp adds $2.00 Extra',
  },
  {
    title: 'D6. Pad Thai',
    price: '$13.95',
    tags: 'Beef, Pork, Chicken, or Tofu | Shrimp adds $2.00 Extra',
  },
  {
    title: 'D7. Coconut Curry Pad Thai',
    price: '$13.95',
    tags: 'Beef, Pork, Chicken, or Tofu | Shrimp adds $2.00 Extra',
  },
]

const kids = [
  {
    title: 'K1. Rice Or Noodle Bowl',
    price: '$6.50',
    tags: 'Beef, Pork, Chicken, or Shrimp',
  },
  {
    title: 'K2. Pho Soup',
    price: '$6.50',
    tags: 'Beef, Pork, Chicken, or Shrimp',
  },
]

const bobas = [
  {
    title: '1. Avocado',
    price: '$5.95',
  },
  {
    title: '2. Strawberry',
    price: '$5.95',
  },
  {
    title: '3. Mango',
    price: '$5.95',
  },
  {
    title: '4. Blue Berry',
    price: '$5.95',
  },
  {
    title: '5. Pineapple',
    price: '$5.95',
  },
  {
    title: '6. Peach',
    price: '$5.95',
  },
  {
    title: '7. Kiwi',
    price: '$5.95',
  },
  {
    title: '8. Honeydew',
    price: '$5.95',
  },
  {
    title: '9. Watermelon',
    price: '$5.95',
  },
  {
    title: '10. Green Apple',
    price: '$5.95',
  },
  {
    title: '11. Milk Tea',
    price: '$5.95',
  },
  {
    title: '12. Banana',
    price: '$5.95',
  },
  {
    title: '13. Taro',
    price: '$5.95',
  },
  {
    title: '14. Lychee',
    price: '$5.95',
  },
  {
    title: '15. Durian',
    price: '$5.95',
  },
  {
    title: '16. Passion Fruit',
    price: '$5.95',
  },
  {
    title: '17. Red Bean',
    price: '$5.95',
  },
  {
    title: '18. Thai Tea',
    price: '$5.95',
  },
  {
    title: '19. Almond',
    price: '$5.95',
  },
  {
    title: '20. Vanilla',
    price: '$5.95',
  },
  {
    title: '21. Coconut',
    price: '$5.95',
  },
  {
    title: '22. Orange',
    price: '$5.95',
  },
  {
    title: '23. Papaya',
    price: '$5.95',
  },
  {
    title: '24. Green Tea',
    price: '$5.95',
  },
  {
    title: '25. Pina Colada',
    price: '$5.95',
  },
  {
    title: '26. Coffee',
    price: '$5.95',
  },
  {
    title: '27. Mocha',
    price: '$5.95',
  },
  {
    title: '28. 2 Seasons',
    price: '$5.95',
  },
  {
    title: '29. 4 Seasons',
    price: '$5.95',
  },
]

const drinks = [
  {
    title: 'R1. Vietnamese Coffee',
    price: '$4.75',
    tags: 'Hot | Iced',
  },
  {
    title: 'R2. Tea',
    price: '$2.50',
    tags: 'Hot | Iced',
  },
  {
    title: 'R3. Fresh Limeade',
    price: '$5.50',
    tags: '',
  },
  {
    title: 'R4. Fresh Limeade Tea',
    price: '$5.50',
    tags: '',
  },
  {
    title: 'R5. Fresh Orange Juice',
    price: '$5.50',
    tags: '',
  },
  {
    title: 'R6. Coconut Juice',
    price: '$5.50',
    tags: '',
  },
  {
    title: 'R7. Ice Thai Tea',
    price: '$4.75',
    tags: '',
  },
  {
    title: 'R8. Fresh Passion Fruit Tea',
    price: '$5.95',
    tags: '',
  },
  {
    title: 'R9. Soft Drink',
    price: '$1.95',
    tags: '',
  },
]

export default {
  appetizers,
  salads,
  phos,
  buns,
  rices,
  fried_rices,
  specicals,
  kids,
  drinks,
  bobas,
}
