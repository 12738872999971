import React from 'react'

import { images } from '../../constants'
import './AboutUs.css'

const AboutUs = () => (
  <div className="app__wrapper section__padding" id="about">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">What we believe in</h1>
      <div className="app__chef-content">
        <p className="p__opensans">
          We serve delicious, exquisite, and best-loved Vietnamese dishes, such
          as Pho, rice noodle bowls, spring rolls, steam rice plates, new,
          special dishes, and much more. With Pho Bom’s well prepared food, nice
          clean fresh atmosphere, friendly, efficient service, you will like our
          restautant from the minute you walk in
        </p>
      </div>
    </div>
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.bun_bo} alt="chef_image" />
    </div>
  </div>
)

export default AboutUs
