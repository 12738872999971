import React from 'react'

import { SubHeading } from '../../components'
import { images } from '../../constants'

const FindUs = () => (
  <div className="app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>
        Find Us
      </h1>
      <h1
        className="headtext__cormorant"
        style={{ marginBottom: '3rem', color: '#274c77' }}
      >
        303-843-6080
      </h1>

      <SubHeading title="8800 E Hampden Ave, Denver, CO 80231" />
      <div className="app__wrapper-content">
        <p
          className="p__cormorant"
          style={{ color: '#DCCA87', margin: '2rem 0' }}
        >
          Opening Hours
        </p>
        <p className="p__cormorant" style={{ color: 'var(--color-primary' }}>
          Mon - Sat: 10:00 am - 9:00 pm
        </p>
        <p className="p__cormorant" style={{ color: 'var(--color-primary' }}>
          Sun: 11:00 am - 08:00 pm
        </p>
      </div>
    </div>
    <div className="app__wrapper_img">
      <img src={images.vermicelli} alt="finus_img" />
    </div>
  </div>
)

export default FindUs
