import React from 'react'

import { SubHeading } from '../../components'
import { images } from '../../constants'
import './Header.css'

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="All you love about vietnamese cuisine right here" />
      <h1 className="app__header-h1">Try our authentic dishes</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>
        Located in the heart of Denver, Colorado, Pho Bom is an authentic
        Vietnamese restaurant where you can enjoy amazing and unique dishes from
        one of the most beautiful countries in South East Asia while you are not
        putting your feet on Vietnam’s territory.
      </p>
      <div className="app__header_callToAction">
        <div className="app__header_callToAction-column">
          <a href="#menu">
            <button type="button" className="custom__button">
              Explore Menu
            </button>
          </a>
        </div>
        <div className="app__header_callToAction-column">
          <a href="https://www.clover.com/online-ordering/pho-bom-denver/">
            <button type="button" className="custom__button_outlined">
              Order Online
            </button>
          </a>
        </div>
      </div>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
)

export default Header
