import bun_bo from '../assets/bun_bo.jpg'
import gallery01 from '../assets/gallery01.jpeg'
import gallery02 from '../assets/gallery02.jpeg'
import gallery03 from '../assets/gallery03.jpeg'
import gallery04 from '../assets/gallery04.jpeg'
import logo from '../assets/logo.png'
import overlaybg from '../assets/overlaybg.png'
import spoon from '../assets/spoon.svg'
import welcome from '../assets/welcome.png'
import vermicelli from '../assets/vermicelli.jpeg'
import smoothie from '../assets/smoothie.jpeg'
import coffee from '../assets/coffee.jpeg'

export default {
  bun_bo,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  smoothie,
  logo,
  overlaybg,
  spoon,
  welcome,
  vermicelli,
  coffee,
}
