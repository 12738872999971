import React from 'react'

import { SubHeading, MenuItem } from '../../components'
import { data, images } from '../../constants'
import './BeverageMenu.css'

const BeverageMenu = () => (
  <div
    className="app__beverageMenu flex__center section__padding"
    id="beverage"
  >
    {/* Drinks */}
    <div className="app__beverageMenu-title">
      <SubHeading title="Sippin’ and chillin’" />
      <h1 className="headtext__cormorant">Drinks</h1>
    </div>
    <div className="app__beverageMenu-menu">
      <div className="app__beverageMenu-menu_wine flex__center">
        <div className="app__beverageMenu_menu_items">
          {data.drinks.slice(0, 4).map((drink, index) => (
            <MenuItem
              key={drink.title + index}
              title={drink.title}
              price={drink.price}
              tags={drink.tags}
            />
          ))}
        </div>
      </div>

      <div className="app__beverageMenu-menu_img">
        <img src={images.coffee} alt="menu__img" />
      </div>

      <div className="app__beverageMenu-menu_cocktails  flex__center">
        <div className="app__beverageMenu_menu_items">
          {data.drinks.slice(4).map((drink, index) => (
            <MenuItem
              key={drink.title + index}
              title={drink.title}
              price={drink.price}
              tags={drink.tags}
            />
          ))}
        </div>
      </div>
    </div>

    {/* Boba */}
    <div className="app__beverageMenu-title">
      <SubHeading title="Whoot..." />
      <h1 className="headtext__cormorant">Boba</h1>
    </div>

    <div className="app__beverageMenu-menu">
      <div className="app__beverageMenu-menu_wine flex__center">
        <div className="app__beverageMenu_menu_items">
          {data.bobas.slice(0, 14).map((boba, index) => (
            <MenuItem
              key={boba.title + index}
              title={boba.title}
              price={boba.price}
              tags={boba.tags}
            />
          ))}
        </div>
      </div>

      <div className="app__beverageMenu-menu_img">
        <img src={images.smoothie} alt="menu__img" />
      </div>

      <div className="app__beverageMenu-menu_cocktails  flex__center">
        <div className="app__beverageMenu_menu_items">
          {data.bobas.slice(14).map((boba, index) => (
            <MenuItem
              key={boba.title + index}
              title={boba.title}
              price={boba.price}
              tags={boba.tags}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default BeverageMenu
