import React from 'react'

import {
  AboutUs,
  FindUs,
  Footer,
  Gallery,
  Header,
  SpecialMenu,
  BeverageMenu,
} from './container'
import { Navbar } from './components'
import './App.css'

const App = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    <SpecialMenu />
    <BeverageMenu />
    <Gallery />
    <FindUs />
    <Footer />
    
  </div>
)

export default App
