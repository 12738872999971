import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';
import './react-tabs.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu that fits your palatte" />
      <h1 className="headtext__cormorant">Our Menu</h1>
    </div>

    <Tabs>
      <TabList className="app__specialMenu__navbar">
        <Tab>
          <button type="button" className="custom__button">
            Appetizers
          </button>
        </Tab>
        <Tab>
          <button type="button" className="custom__button">
            Salad
          </button>
        </Tab>
        <Tab>
          <button type="button" className="custom__button">
            Pho
          </button>
        </Tab>
        <Tab>
          <button type="button" className="custom__button">
            Vermicelli
          </button>
        </Tab>
        <Tab>
          <button type="button" className="custom__button">
            Rice
          </button>
        </Tab>
        <Tab>
          <button type="button" className="custom__button">
            Special Dishes
          </button>
        </Tab>
        <Tab>
          <button type="button" className="custom__button">
            Kid
          </button>
        </Tab>
      </TabList>

      {/* //Appetizers */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center">
          <p className="app__specialMenu-menu_heading">Appetizers</p>
          <div className="app__specialMenu_menu_items">
            {data.appetizers.map((appetizer, index) => (
              <MenuItem
                key={appetizer.title + index}
                title={appetizer.title}
                price={appetizer.price}
                tags={appetizer.tags}
              />
            ))}
          </div>
        </div>
      </TabPanel>

      {/* Salad */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center">
          <p className="app__specialMenu-menu_heading">Salad</p>
          <div className="app__specialMenu_menu_items">
            {data.salads.map((salad, index) => (
              <MenuItem
                key={salad.title + index}
                title={salad.title}
                price={salad.price}
                tags={salad.tags}
              />
            ))}
          </div>
        </div>
      </TabPanel>

      {/* Pho */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center">
          <p className="app__specialMenu-menu_heading">Pho</p>
          <div className="app__specialMenu-title">
            <SubHeading title="Noodle Soup served with Bean Sprouts, Basil, Lime, and Jalapeno" />
          </div>
          <p
            className="app__specialMenu-menu_subheading"
            style={{ color: 'var(--color-crimson)' }}
          >
            Small | Medium | Large
          </p>
          <p className="app__specialMenu-menu_subheading">
            $12.50 | $13.50 | $15.50
          </p>

          <div className="app__specialMenu_menu_items">
            {data.phos.map((pho, index) => (
              <MenuItem
                key={pho.title + index}
                title={pho.title}
                tags={pho.tags}
              />
            ))}
          </div>
        </div>
        <p className="app__specialMenu-menu_notes">
          * Additional $1.00 for spicy soup
        </p>
        <p className="app__specialMenu-menu_notes">
          ** Raw Beef or undercooked ingredients
        </p>
      </TabPanel>

      {/* Vermicelli */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center">
          <p className="app__specialMenu-menu_heading">Vermicelli</p>
          <div className="app__specialMenu-title">
            <SubHeading title="A bowl of Rice Noodle topped with marinated choice of Grilled Chicken Breast, Pork, Beef, Vegetarian & Tofu, Shrimp or Vietnamese Eggrolls served with Lettuce, Cucumber, Bean Sprouts, Peanut & Fish Sauce." />
          </div>
          <div className="app__specialMenu_menu_items">
            {data.buns.map((bun, index) => (
              <MenuItem
                key={bun.title + index}
                title={bun.title}
                price={bun.price}
                tags={bun.tags}
              />
            ))}
          </div>
        </div>
      </TabPanel>

      {/* Rices */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center ">
          <p className="app__specialMenu-menu_heading">Rice</p>
          <div className="app__specialMenu-title">
            <SubHeading title="White, Brown, or Fried Rice Plate of Rice topped with marinated choice of Grilled Chicken Breast, Pork, Beef, Shrimp, Pork chop, Dice Steak, and Grilled Salmon; served with Lettuce, Cucumber, and Tomatoes with Fish Sauce." />
          </div>
          <div className="app__specialMenu_menu_items">
            {data.rices.map((rice, index) => (
              <MenuItem
                key={rice.title + index}
                title={rice.title}
                price={rice.price}
              />
            ))}
          </div>

          <p className="app__specialMenu-menu_heading">Fried Rice</p>
          <div className="app__specialMenu_menu_items">
            {data.fried_rices.map((rice, index) => (
              <MenuItem
                key={rice.title + index}
                title={rice.title}
                price={rice.price}
              />
            ))}
          </div>
        </div>
      </TabPanel>

      {/* Specials */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center">
          <p className="app__specialMenu-menu_heading">Special Dishes</p>
          <div className="app__specialMenu-title">
            <SubHeading title="D1 to D5 are served with Rice & Eggrolls" />
          </div>
          <div className="app__specialMenu_menu_items">
            {data.specicals.map((special, index) => (
              <MenuItem
                key={special.title + index}
                title={special.title}
                price={special.price}
                tags={special.tags}
              />
            ))}
          </div>
          <p className="app__specialMenu-menu_notes">
            Additions: White Rice, Brown Rice or Fried Rice +$1.00
          </p>
        </div>
      </TabPanel>

      {/* Specials */}
      <TabPanel>
        <div className="app__specialMenu-menu_items flex__center">
          <p className="app__specialMenu-menu_heading">Kid</p>
          <div className="app__specialMenu-title">
            <SubHeading title="For children under 12 only" />
          </div>
          <div className="app__specialMenu_menu_items">
            {data.kids.map((kid, index) => (
              <MenuItem
                key={kid.title + index}
                title={kid.title}
                price={kid.price}
                tags={kid.tags}
              />
            ))}
          </div>
        </div>
      </TabPanel>
    </Tabs>
  </div>
);

export default SpecialMenu;
